import React from "react"
import SEO from "../components/seo"
import {ErrorWrapper} from "../components/404/error.style";
import {ErrorImage} from "../components/svg/svg.component";
import {Container, Paragraph, Title} from "../components/home-about/home-about.style";
import Button from "../components/button/button.component";
import {Global} from "../components/global";


const NotFoundPage = () => {

    return (
        <>
            <Global/>
            <SEO title="404: Not found"/>
            <ErrorWrapper>
                <ErrorImage/>
                <Container>
                    <Title>Ooops</Title>
                    <Paragraph>You just hit a route that doesn&#39;t exist... the sadness.</Paragraph>
                    <Button link='/' type='primary'>Take me home</Button>
                </Container>
            </ErrorWrapper>
        </>
    );

}

export default NotFoundPage
